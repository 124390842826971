<template>
  <v-row
    justify="center"
    class="mt-12"
  >
    <v-slide-y-transition appear>
      <BaseMaterialCard
        color="accent"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template v-slot:heading>
          <div class="text-center">
            <v-img
              src="@/assets/logo_white.png"
              class="ma-2"
            />
          </div>
        </template>

        <v-card-text class="text-center">
          <v-form>
            <p
              justify="start"
              class="text-subtitle-1 font-italic"
            >
              Forgot Password?
            </p>

            <p
              v-if="error"
              class="red--text font-weight-bold text-left"
            >
              <v-icon
                color="red"
                class="mr-1"
              >
                mdi-alert-circle-outline
              </v-icon>
              {{ error }}
            </p>

            <p
              v-if="message"
              class="success--text text-left"
            >
              <v-icon
                color="success"
                class="mr-1"
              >
                mdi-check-circle
              </v-icon>
              {{ message }}
            </p>

            <v-text-field
              v-model="email"
              color="secondary"
              prepend-icon="mdi-email-outline"
              :error-messages="emailErrors"
              required
              name="email"
              label="Email"
              type="email"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              @keyup.enter="submit()"
            />

            <v-row justify="start">
              <router-link :to="{ name: 'AppLogin' }">
                <v-btn
                  text
                  color="primary"
                  class="ml-7"
                  tag="button"
                >
                  Oh, I remembered!
                </v-btn>
              </router-link>
            </v-row>

            <pages-btn
              large
              color="secondary"
              depressed
              class="v-btn--text success--text mt-4"
              @click="submit()"
            >
              <v-icon left>
                mdi-email-lock
              </v-icon>Let's go
            </pages-btn>
          </v-form>
        </v-card-text>
      </BaseMaterialCard>
    </v-slide-y-transition>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('auth')

export default {
  name: 'ForgotPassword',

  components: {
    PagesBtn: () => import('../components/Btn')
  },

  mixins: [validationMixin],

  validations: {
    email: { required, email }
  },

  data: () => ({
    error: null,
    message: null,
    email: null
  }),

  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid email')
      !this.$v.email.required && errors.push('Email is required')
      return errors
    }
  },

  methods: {
    ...mapActions(['forgotPassword']),
    async submit () {
      this.error = null
      this.message = null

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const data = await this.forgotPassword({
        email: this.email
      })

      if (data.errorResponse) {
        this.$appInsights.trackEvent({ name: 'ForgotPassword Failed' }, { userName: this.email })
        this.error = data.errorResponse
        return
      }
      this.$appInsights.trackEvent({ name: 'ForgotPassword Initiated' }, { userName: this.email })
      this.message =
        'You will receive an email shortly with a link to reset your password.'
    }
  }
}
</script>
